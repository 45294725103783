// vendors
import React from "react"
import { css } from "@emotion/core"

const LinkGroup = ({ children }) => (
  <div
    css={css`
      margin: 3.5em 0 7.25em;

      grid-row-gap: 1em;
      justify-items: start;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(221px, 1fr));
    `}
  >
    {children}
  </div>
)

export default LinkGroup
