import React from "react"

const VectorMap = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 133.09 135.08"
    {...props}
  >
    <g>
      <polyline
        points="14.27 40.67 4 44.51 4 129.31 46.79 113.31 87.98 128.98 129.09 112.94 129.09 28.14 87.98 44.18 51.47 30.29"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="8"
      />
      <line
        x1="87.73"
        y1="43.66"
        x2="87.73"
        y2="129.46"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="8"
      />
      <line
        x1="46.96"
        y1="42.57"
        x2="46.96"
        y2="114.49"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="8"
      />
      <path
        d="M5,26.12c0,9.45,7,19,13,25.32A100,100,0,0,0,30.85,63l.25.18.24-.18A99.91,99.91,0,0,0,44.25,51.38c5.92-6.35,13-15.89,13-25.26A26.12,26.12,0,0,0,5,26.12ZM31.1,8.47A16.53,16.53,0,0,1,47.74,24.85c0,10.26-9,20.5-16.65,27.37C20.2,42.48,14.45,33,14.45,24.85A16.54,16.54,0,0,1,31.1,8.47Z"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default VectorMap
