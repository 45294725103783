import React from "react"

const VectorCalendar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.24 131.99">
    <g>
      <path
        d="M60.27,26.12c0,9.45,7,19,13,25.32A100,100,0,0,0,86.14,63l.25.18.24-.18A99.17,99.17,0,0,0,99.54,51.38c5.92-6.35,13-15.89,13-25.26a26.12,26.12,0,0,0-52.24,0ZM86.39,8.47A16.53,16.53,0,0,1,103,24.85c0,10.26-9,20.5-16.65,27.37C75.49,42.48,69.74,33,69.74,24.85A16.54,16.54,0,0,1,86.39,8.47Z"
        fill="currentColor"
      />
      <polyline
        points="108.2 33.64 118.23 33.64 118.23 127.98 4.01 127.98 4.01 33.64 70.74 33.64"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="8.03"
      />
      <line
        x1="11.96"
        y1="32.05"
        x2="11.96"
        y2="16.75"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="8.03"
      />
      <line
        x1="30.41"
        y1="32.05"
        x2="30.41"
        y2="16.75"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="8.03"
      />
      <line
        x1="48.87"
        y1="32.05"
        x2="48.87"
        y2="16.75"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="8.03"
      />
      <line
        x1="27.28"
        y1="62"
        x2="42.37"
        y2="62"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="8.03"
      />
      <line
        x1="27.28"
        y1="83.43"
        x2="42.37"
        y2="83.43"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="8.03"
      />
      <line
        x1="53.35"
        y1="62"
        x2="68.45"
        y2="62"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="8.03"
      />
      <line
        x1="53.35"
        y1="83.43"
        x2="68.45"
        y2="83.43"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="8.03"
      />
      <line
        x1="79.33"
        y1="83.43"
        x2="94.42"
        y2="83.43"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="8.03"
      />
      <line
        x1="27.28"
        y1="104.86"
        x2="42.37"
        y2="104.86"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="8.03"
      />
      <line
        x1="53.35"
        y1="104.86"
        x2="68.45"
        y2="104.86"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="8.03"
      />
      <line
        x1="79.33"
        y1="104.86"
        x2="94.42"
        y2="104.86"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="8.03"
      />
    </g>
  </svg>
)

export default VectorCalendar
